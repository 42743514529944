<template>
    <div class="page-template">
        <slot name="head">
            <div v-if="rightConfig" class="operate">
                <div class="left"></div>
                <div class="right" v-if="rightConfig.text">
                    <el-button el-button type="primary" @click="rightConfig.handler">
                        <!-- <i class="el-icon-plus" style="margin-right: 5px"></i> -->
                        {{ rightConfig.text }}
                    </el-button>
                </div>
            </div>
        </slot>
        <div class="table">
            <el-scrollbar style="height: 100%">
                <table-list :table-config="tableConfig" :table-data="tableData" :row-key="tableRowKey">
                    <template #default="{ item }">
                        <slot name="tableOperate" :item="item"></slot>
                    </template>
                </table-list>
            </el-scrollbar>
        </div>
        <div v-if="pagingConfig" class="paging-box pages-center">
            <el-pagination
                :current-page.sync="pagingConfig.page"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="totalCount"
                @current-change="pageChange"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import TableList from "./TableList";
export default {
    name: "TableListPageTemplate",
    data() {
        return {};
    },
    components: {
        TableList,
    },
    props: {
        rightConfig: {
            type: Object,
            default: () => ({
                text: "",
                handler: () => {},
            }),
        },
        tableConfig: {
            type: Array,
            default: () => [],
        },
        tableData: {
            type: Array,
            default: () => [],
        },
        pagingConfig: {
            type: Object,
            default: () => {},
        },
        totalCount: {
            type: Number,
            default: 1,
        },
        tableRowKey: {
            type: String,
            default: "",
        },
    },
    computed: {
        pageSize() {
            let keys = ["pageSize", "limit"];
            let tag = Object.keys(this.pagingConfig).find((itemI) => keys.some((itemJ) => itemJ == itemI));
            return tag ? this.pagingConfig[tag] : 1;
        },
    },
    methods: {
        pageChange(value) {
            this.$emit("page-change", value);
        },
    },
};
</script>

<style scoped lang="scss">
.page-template {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    .operate {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
    }
    .table {
        flex: 1;
        overflow: hidden;
    }
    .paging-box {
        padding: 20px 0 30px;
        text-align: center;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
